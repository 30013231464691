.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  background: #ffffff;
}
.box__cqMls {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.img__t7Tze {
  position: relative;
  object-fit: cover;
  width: 248px;
  height: 130px;
  align-self: center;
  margin-top: 50px;
  flex-shrink: 0;
}
.box__bBxHd {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 513px;
  margin-top: 51px;
  margin-bottom: 106px;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .box__bBxHd {
    display: flex;
    flex-direction: column;
    height: auto;
    flex-column-gap: 0px;
  }
}
.videoBox {
  display: flex;
  position: relative;
  width: 63%;
  height: 513px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 36px;
  margin-right: 36px;
  background: #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .videoBox {
    width: 100%;
    height: auto;
    min-height: 320px;
    margin-left: 0px;
    margin-right: 0px;
    min-width: 0;
  }
}
.chatBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 29%;
  border: 5px solid #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .chatBox {
    margin-top: 106px;
    width: 100%;
  }
}
.chatroll {
  display: block;
  width: 101%;
  height: 100%;
  min-height: 0;
  border-style: solid;
  border-color: #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .chatroll {
    width: 100%;
    height: 480px;
    min-height: 320px;
    min-width: 0;
    flex-shrink: 0;
  }
}
.disclaimer {
  position: relative;
  height: auto;
  text-align: left;
  font-family: DINPro-Light;
  font-size: 11.5px;
  line-height: 12px;
  padding: 5px;
}
.photoboothBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin-top: 0px;
}
.photoboothGif {
  object-fit: cover;
  width: 100%;
  height: auto;
  min-width: 0;
}
.photoboothLogo {
  position: absolute;
  object-fit: cover;
  left: auto;
  top: auto;
  width: 30%;
  height: 17%;
  align-self: center;
  right: auto;
  bottom: 57px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .photoboothLogo {
    width: 54px;
    height: 54px;
    bottom: 5px;
    flex-shrink: 0;
  }
}
.carousel {
  display: block;
  position: relative;
  width: 100%;
  height: 421px;
  margin-top: 86px;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .carousel {
    height: 421px;
    flex-shrink: 0;
  }
}
.breakouts {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 86px;
}
.img___9WRzr {
  object-fit: cover;
  z-index: 0;
}
.body {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  z-index: 1;
  align-self: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.box__oRiod {
  width: auto;
  font-family: GrotesqueMTStd-ExtraCond;
  font-size: 100px;
  line-height: 110%;
  text-transform: uppercase;
  margin-top: 129px;
  color: #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .box__oRiod {
    font-size: 36px;
    margin-top: 8px;
  }
}
@media (max-width: 320px) {
  .box__oRiod {
    font-size: 24px;
  }
}
.box__qrCx1 {
  position: relative;
  text-decoration-line: none;
  font-size: 24px;
  line-height: 120%;
  font-family: DINPro-Bold;
  text-transform: uppercase;
  white-space: pre-wrap;
  flex-shrink: 0;
  width: 726px;
  align-self: center;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 96px;
  color: #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .box__qrCx1 {
    font-size: 12px;
    width: 261px;
    margin-bottom: -22px;
  }
}
@media (max-width: 320px) {
  .box__qrCx1 {
    font-size: 10px;
    margin-bottom: 0px;
  }
}
.buttons {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: auto;
  top: auto;
  width: 100%;
  margin-top: 40px;
  min-width: 0;
}
@media (max-width: 320px) {
  .buttons {
    align-self: auto;
    align-items: center;
    margin: 0px;
  }
}
.firstBreakout {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-right: 180px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .firstBreakout {
    margin-right: 40px;
    width: 96px;
    flex-shrink: 0;
  }
}
.img__tr4G2 {
  object-fit: cover;
  align-self: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__tr4G2 {
    width: 83px;
    height: 73px;
    align-self: center;
    flex-shrink: 0;
  }
}
.clutchText {
  position: relative;
  font-family: GrotesqueMTStd-ExtraCond;
  font-size: 28px;
  text-align: center;
  color: #c8aa74;
  padding-top: 24px;
}
@media (max-width: 320px) {
  .clutchText {
    align-self: baseline;
    padding: 0px;
  }
}
.img__kb5C9 {
  position: relative;
  object-fit: cover;
  margin-top: 15px;
}
.secondBreakout {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 197px;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .secondBreakout {
    width: 100px;
    flex-shrink: 0;
  }
}
.img__gOvO3 {
  object-fit: cover;
  width: 110px;
  height: 181px;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__gOvO3 {
    width: 58px;
    height: 77px;
    flex-shrink: 0;
  }
}
.clutchText2 {
  position: relative;
  font-family: GrotesqueMTStd-ExtraCond;
  font-size: 28px;
  text-align: center;
  color: #c8aa74;
  padding-top: 24px;
}
@media (max-width: 320px) {
  .clutchText2 {
    padding: 0px;
  }
}
.img___32Y0R {
  position: relative;
  object-fit: cover;
  object-position: center center;
  left: auto;
  top: auto;
  margin-top: 33px;
}
