@import url(https://fonts.googleapis.com/css2?family=Roboto%3Aital%2Cwght%400%2C400%3B0%2C500&display=swap);
@font-face {
  font-family: 'DINPro-Black';
  src: url('https://twcdn.s3.us-west-2.amazonaws.com/valentino/DINPro-Black.woff2') format('woff2'),
      url('https://twcdn.s3.us-west-2.amazonaws.com/valentino/DINPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DINPro-Bold';
  src: url('https://twcdn.s3.us-west-2.amazonaws.com/valentino/DINPro-Bold.woff2') format('woff2'),
      url('https://twcdn.s3.us-west-2.amazonaws.com/valentino/DINPro-Bold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DINPro-Light';
  src: url('https://twcdn.s3.us-west-2.amazonaws.com/valentino/DINPro-Light.woff2') format('woff2'),
      url('https://twcdn.s3.us-west-2.amazonaws.com/valentino/DINPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GrotesqueMTStd-ExtraCond';
  src: url('https://twcdn.s3.us-west-2.amazonaws.com/valentino/GrotesqueMTStd-ExtraCond.woff2') format('woff2'),
      url('https://twcdn.s3.us-west-2.amazonaws.com/valentino/GrotesqueMTStd-ExtraCond.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'DINPro-Medium';
  src: url('https://twcdn.s3.us-west-2.amazonaws.com/valentino/DINPro-Medium.woff2') format('woff2'),
      url('https://twcdn.s3.us-west-2.amazonaws.com/valentino/DINPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  color: black;
}
::-webkit-input-placeholder {
  color: black;
  opacity: 1;
}
:-ms-input-placeholder {
  color: black;
  opacity: 1;
}
::placeholder {
  color: black;
  opacity: 1;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.plasmic__default_style_all__3sG9G {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-duration: 0s;
  margin: 0;
  border-width: 0px;
}
.plasmic__default_style_img__3l2Wd {
  display: inline-block;
}
.plasmic__default_style_input__2xYgx {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  background: #ffffff;
  border: 1px solid lightgray;
}
.plasmic__default_style_textarea__e2K-X {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
.plasmic__default_style_button__1V5zs {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px 6px;
  background: none;
  border: 1px solid lightgray;
}
.plasmic__default_style_code__1ReMw {
  font-family: inherit;
  line-height: inherit;
}
.plasmic__default_style_pre__1pEPW {
  font-family: inherit;
  line-height: inherit;
}
.plasmic__default_style_h1__2olhD {
  font-size: inherit;
  font-weight: inherit;
}
.plasmic__default_style_h2__1m5NQ {
  font-size: inherit;
  font-weight: inherit;
}
.plasmic__default_style_h3__xWs4w {
  font-size: inherit;
  font-weight: inherit;
}
.plasmic__default_style_h4__39vKd {
  font-size: inherit;
  font-weight: inherit;
}
.plasmic__default_style_h5__1sEmu {
  font-size: inherit;
  font-weight: inherit;
}
.plasmic__default_style_h6__MNLyy {
  font-size: inherit;
  font-weight: inherit;
}
.plasmic__default_style_address__1Yp0H {
  font-style: inherit;
}
.plasmic__default_style_ol__24TDx {
  list-style-type: none;
  padding: 0;
}
.plasmic__default_style_ul__20SZ1 {
  list-style-type: none;
  padding: 0;
}
.plasmic__default_style_select__1XYRx {
  padding: 2px 6px;
}
.plasmic__default_style_li__1kiGb {
  list-style-type: none;
}
.plasmic__default_style_plasmic_default__component_wrapper__1aoLR {
  display: grid;
}
.plasmic__default_style___wab_text__3FkrX {
  display: inline-block;
}
.plasmic__default_style_plasmic_page_wrapper__1qPw_ {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic__default_style_plasmic_page_wrapper__1qPw_ > * {
  height: auto !important;
}

.plasmic_valentino_root_reset__DOIoK {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
}

.PlasmicValentinoBeauty_root__3XoQ1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  background: #ffffff;
}
.PlasmicValentinoBeauty_formBody__TEFeN {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-self: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicValentinoBeauty_formBody__TEFeN {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 0;
  }
}
.PlasmicValentinoBeauty_logo__Zyzzx {
  position: relative;
  object-fit: cover;
  object-position: center top;
  left: auto;
  top: auto;
  height: 130px;
  width: 247px;
  align-self: center;
  margin-top: 50px;
  flex-shrink: 0;
}
.PlasmicValentinoBeauty_firstName__3D4ZH {
  position: relative;
  margin-top: 74px;
  margin-bottom: 7px;
  font-family: DINPro-Black;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  color: #000000;
}
.PlasmicValentinoBeauty_firstNameTextbox__2uSch {
  font-family: DINPro-Light;
  position: relative;
  width: 446px;
  letter-spacing: 2px;
  height: 40px;
  color: #000000;
  flex-shrink: 0;
  padding: 15px;
  border: 1px solid #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicValentinoBeauty_firstNameTextbox__2uSch {
    width: 100%;
    min-width: 0;
  }
}
.PlasmicValentinoBeauty_lastName__2nzLs {
  position: relative;
  margin-top: 39px;
  margin-bottom: 7px;
  font-family: DINPro-Black;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  color: #000000;
}
.PlasmicValentinoBeauty_lastNameTextbox__100qL {
  font-family: DINPro-Light;
  position: relative;
  width: 446px;
  letter-spacing: 2px;
  height: 40px;
  color: #000000;
  flex-shrink: 0;
  padding: 15px;
  border: 1px solid #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicValentinoBeauty_lastNameTextbox__100qL {
    width: 100%;
    min-width: 0;
  }
}
.PlasmicValentinoBeauty_email__3aSj5 {
  position: relative;
  margin-top: 39px;
  margin-bottom: 7px;
  font-family: DINPro-Black;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  height: auto;
  color: #000000;
}
.PlasmicValentinoBeauty_emailTextbox__1Z2R0 {
  font-family: DINPro-Light;
  position: relative;
  width: 446px;
  letter-spacing: 2px;
  height: 40px;
  transition-property: all;
  transition-duration: 1s;
  color: #000000;
  flex-shrink: 0;
  padding: 15px;
  border: 1px solid #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicValentinoBeauty_emailTextbox__1Z2R0 {
    width: 100%;
    min-width: 0;
  }
}
.PlasmicValentinoBeauty_loginButton__7YVuz {
  position: relative;
  width: 150px;
  height: 40px;
  align-self: center;
  margin-top: 84px;
  color: #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: DINPro-Medium;
  font-size: 13px;
  font-weight: 400;
  flex-shrink: 0;
  border: 1px solid #000000;
}
.PlasmicValentinoBeauty_root__3XoQ1 .PlasmicValentinoBeauty_loginButton__7YVuz:hover {
  color: #ffffff;
  font-weight: 500;
  background: #000000;
  border-width: 0px;
}
.PlasmicValentinoBeauty_root__3XoQ1 .PlasmicValentinoBeauty_loginButton__7YVuz:focus {
  color: #000000;
  outline: none;
  background: none;
  border-width: 2px;
}

.PlasmicLanding_root__3JVqP {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  background: #ffffff;
}
.PlasmicLanding_box__cqMls__1GcBZ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicLanding_img__t7Tze__2U-JE {
  position: relative;
  object-fit: cover;
  width: 248px;
  height: 130px;
  align-self: center;
  margin-top: 50px;
  flex-shrink: 0;
}
.PlasmicLanding_box__bBxHd__Q9zAZ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 513px;
  margin-top: 51px;
  margin-bottom: 106px;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicLanding_box__bBxHd__Q9zAZ {
    display: flex;
    flex-direction: column;
    height: auto;
    flex-column-gap: 0px;
  }
}
.PlasmicLanding_videoBox__KuARG {
  display: flex;
  position: relative;
  width: 63%;
  height: 513px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 36px;
  margin-right: 36px;
  background: #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicLanding_videoBox__KuARG {
    width: 100%;
    height: auto;
    min-height: 320px;
    margin-left: 0px;
    margin-right: 0px;
    min-width: 0;
  }
}
.PlasmicLanding_chatBox__wAqaS {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 29%;
  border: 5px solid #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicLanding_chatBox__wAqaS {
    margin-top: 106px;
    width: 100%;
  }
}
.PlasmicLanding_chatroll__14O5R {
  display: block;
  width: 101%;
  height: 100%;
  min-height: 0;
  border-style: solid;
  border-color: #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicLanding_chatroll__14O5R {
    width: 100%;
    height: 480px;
    min-height: 320px;
    min-width: 0;
    flex-shrink: 0;
  }
}
.PlasmicLanding_disclaimer__2Vps- {
  position: relative;
  height: auto;
  text-align: left;
  font-family: DINPro-Light;
  font-size: 11.5px;
  line-height: 12px;
  padding: 5px;
}
.PlasmicLanding_photoboothBox__2xuTO {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin-top: 0px;
}
.PlasmicLanding_photoboothGif__1lp8j {
  object-fit: cover;
  width: 100%;
  height: auto;
  min-width: 0;
}
.PlasmicLanding_photoboothLogo__3Wdt7 {
  position: absolute;
  object-fit: cover;
  left: auto;
  top: auto;
  width: 30%;
  height: 17%;
  align-self: center;
  right: auto;
  bottom: 57px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicLanding_photoboothLogo__3Wdt7 {
    width: 54px;
    height: 54px;
    bottom: 5px;
    flex-shrink: 0;
  }
}
.PlasmicLanding_carousel__3ZNaO {
  display: block;
  position: relative;
  width: 100%;
  height: 421px;
  margin-top: 86px;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicLanding_carousel__3ZNaO {
    height: 421px;
    flex-shrink: 0;
  }
}
.PlasmicLanding_breakouts___lfo9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 86px;
}
.PlasmicLanding_img___9WRzr__Alssk {
  object-fit: cover;
  z-index: 0;
}
.PlasmicLanding_body__3WsPy {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  z-index: 1;
  align-self: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.PlasmicLanding_box__oRiod__rcGrR {
  width: auto;
  font-family: GrotesqueMTStd-ExtraCond;
  font-size: 100px;
  line-height: 110%;
  text-transform: uppercase;
  margin-top: 129px;
  color: #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicLanding_box__oRiod__rcGrR {
    font-size: 36px;
    margin-top: 8px;
  }
}
@media (max-width: 320px) {
  .PlasmicLanding_box__oRiod__rcGrR {
    font-size: 24px;
  }
}
.PlasmicLanding_box__qrCx1__1-oAr {
  position: relative;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  font-size: 24px;
  line-height: 120%;
  font-family: DINPro-Bold;
  text-transform: uppercase;
  white-space: pre-wrap;
  flex-shrink: 0;
  width: 726px;
  align-self: center;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 96px;
  color: #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicLanding_box__qrCx1__1-oAr {
    font-size: 12px;
    width: 261px;
    margin-bottom: -22px;
  }
}
@media (max-width: 320px) {
  .PlasmicLanding_box__qrCx1__1-oAr {
    font-size: 10px;
    margin-bottom: 0px;
  }
}
.PlasmicLanding_buttons__2qaFx {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: auto;
  top: auto;
  width: 100%;
  margin-top: 40px;
  min-width: 0;
}
@media (max-width: 320px) {
  .PlasmicLanding_buttons__2qaFx {
    align-self: auto;
    align-items: center;
    margin: 0px;
  }
}
.PlasmicLanding_firstBreakout__y39Ei {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-right: 180px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicLanding_firstBreakout__y39Ei {
    margin-right: 40px;
    width: 96px;
    flex-shrink: 0;
  }
}
.PlasmicLanding_img__tr4G2__3dLHb {
  object-fit: cover;
  align-self: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicLanding_img__tr4G2__3dLHb {
    width: 83px;
    height: 73px;
    align-self: center;
    flex-shrink: 0;
  }
}
.PlasmicLanding_clutchText__3h7OD {
  position: relative;
  font-family: GrotesqueMTStd-ExtraCond;
  font-size: 28px;
  text-align: center;
  color: #c8aa74;
  padding-top: 24px;
}
@media (max-width: 320px) {
  .PlasmicLanding_clutchText__3h7OD {
    align-self: baseline;
    padding: 0px;
  }
}
.PlasmicLanding_img__kb5C9__3vhiJ {
  position: relative;
  object-fit: cover;
  margin-top: 15px;
}
.PlasmicLanding_secondBreakout__sAkRP {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 197px;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicLanding_secondBreakout__sAkRP {
    width: 100px;
    flex-shrink: 0;
  }
}
.PlasmicLanding_img__gOvO3__32rb_ {
  object-fit: cover;
  width: 110px;
  height: 181px;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicLanding_img__gOvO3__32rb_ {
    width: 58px;
    height: 77px;
    flex-shrink: 0;
  }
}
.PlasmicLanding_clutchText2__ihsma {
  position: relative;
  font-family: GrotesqueMTStd-ExtraCond;
  font-size: 28px;
  text-align: center;
  color: #c8aa74;
  padding-top: 24px;
}
@media (max-width: 320px) {
  .PlasmicLanding_clutchText2__ihsma {
    padding: 0px;
  }
}
.PlasmicLanding_img___32Y0R__3Rdrl {
  position: relative;
  object-fit: cover;
  object-position: center center;
  left: auto;
  top: auto;
  margin-top: 33px;
}

.PlasmicPreevent_root__3Fq3B {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  background: #ffffff;
}
.PlasmicPreevent_formBody__2Cfvp {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-self: center;
  left: 0px;
  top: 50px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPreevent_formBody__2Cfvp {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 0;
  }
}
.PlasmicPreevent_logo__2DIp2 {
  position: relative;
  object-fit: cover;
  object-position: center top;
  left: auto;
  top: auto;
  height: 130px;
  width: 247px;
  align-self: center;
  margin-top: 0px;
  flex-shrink: 0;
}
.PlasmicPreevent_box__tzK__3NjOh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: auto;
  left: auto;
  align-self: center;
  width: 100%;
  height: 100%;
}
.PlasmicPreevent_box__gyu1U__z1hil {
  height: auto;
  text-align: center;
  font-family: GrotesqueMTStd-ExtraCond;
  text-transform: uppercase;
  font-size: 48px;
  color: #000000;
  align-self: center;
  line-height: 53px;
  letter-spacing: 0.13em;
  width: 660px;
}

