.all {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-duration: 0s;
  margin: 0;
  border-width: 0px;
}
.img {
  composes: all;
  display: inline-block;
}
.input {
  composes: all;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  background: #ffffff;
  border: 1px solid lightgray;
}
.textarea {
  composes: all;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
.button {
  composes: all;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px 6px;
  background: none;
  border: 1px solid lightgray;
}
.code {
  composes: all;
  font-family: inherit;
  line-height: inherit;
}
.pre {
  composes: all;
  font-family: inherit;
  line-height: inherit;
}
.h1 {
  composes: all;
  font-size: inherit;
  font-weight: inherit;
}
.h2 {
  composes: all;
  font-size: inherit;
  font-weight: inherit;
}
.h3 {
  composes: all;
  font-size: inherit;
  font-weight: inherit;
}
.h4 {
  composes: all;
  font-size: inherit;
  font-weight: inherit;
}
.h5 {
  composes: all;
  font-size: inherit;
  font-weight: inherit;
}
.h6 {
  composes: all;
  font-size: inherit;
  font-weight: inherit;
}
.address {
  composes: all;
  font-style: inherit;
}
.ol {
  composes: all;
  list-style-type: none;
  padding: 0;
}
.ul {
  composes: all;
  list-style-type: none;
  padding: 0;
}
.select {
  composes: all;
  padding: 2px 6px;
}
.li {
  composes: all;
  list-style-type: none;
}
.plasmic_default__component_wrapper {
  display: grid;
}
.__wab_text {
  display: inline-block;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
