.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  background: #ffffff;
}
.formBody {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-self: center;
  left: 0px;
  top: 50px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .formBody {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 0;
  }
}
.logo {
  position: relative;
  object-fit: cover;
  object-position: center top;
  left: auto;
  top: auto;
  height: 130px;
  width: 247px;
  align-self: center;
  margin-top: 0px;
  flex-shrink: 0;
}
.box__tzK {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: auto;
  left: auto;
  align-self: center;
  width: 100%;
  height: 100%;
}
.box__gyu1U {
  height: auto;
  text-align: center;
  font-family: GrotesqueMTStd-ExtraCond;
  text-transform: uppercase;
  font-size: 48px;
  color: #000000;
  align-self: center;
  line-height: 53px;
  letter-spacing: 0.13em;
  width: 660px;
}
