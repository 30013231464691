.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  background: #ffffff;
}
.formBody {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-self: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .formBody {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 0;
  }
}
.logo {
  position: relative;
  object-fit: cover;
  object-position: center top;
  left: auto;
  top: auto;
  height: 130px;
  width: 247px;
  align-self: center;
  margin-top: 50px;
  flex-shrink: 0;
}
.firstName {
  position: relative;
  margin-top: 74px;
  margin-bottom: 7px;
  font-family: DINPro-Black;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  color: #000000;
}
.firstNameTextbox {
  font-family: DINPro-Light;
  position: relative;
  width: 446px;
  letter-spacing: 2px;
  height: 40px;
  color: #000000;
  flex-shrink: 0;
  padding: 15px;
  border: 1px solid #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .firstNameTextbox {
    width: 100%;
    min-width: 0;
  }
}
.lastName {
  position: relative;
  margin-top: 39px;
  margin-bottom: 7px;
  font-family: DINPro-Black;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  color: #000000;
}
.lastNameTextbox {
  font-family: DINPro-Light;
  position: relative;
  width: 446px;
  letter-spacing: 2px;
  height: 40px;
  color: #000000;
  flex-shrink: 0;
  padding: 15px;
  border: 1px solid #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .lastNameTextbox {
    width: 100%;
    min-width: 0;
  }
}
.email {
  position: relative;
  margin-top: 39px;
  margin-bottom: 7px;
  font-family: DINPro-Black;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  height: auto;
  color: #000000;
}
.emailTextbox {
  font-family: DINPro-Light;
  position: relative;
  width: 446px;
  letter-spacing: 2px;
  height: 40px;
  transition-property: all;
  transition-duration: 1s;
  color: #000000;
  flex-shrink: 0;
  padding: 15px;
  border: 1px solid #000000;
}
@media (min-width: 0px) and (max-width: 768px) {
  .emailTextbox {
    width: 100%;
    min-width: 0;
  }
}
.loginButton {
  position: relative;
  width: 150px;
  height: 40px;
  align-self: center;
  margin-top: 84px;
  color: #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: DINPro-Medium;
  font-size: 13px;
  font-weight: 400;
  flex-shrink: 0;
  border: 1px solid #000000;
}
.root .loginButton:hover {
  color: #ffffff;
  font-weight: 500;
  background: #000000;
  border-width: 0px;
}
.root .loginButton:focus {
  color: #000000;
  outline: none;
  background: none;
  border-width: 2px;
}
